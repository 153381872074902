import React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  const hjid = 3088760
  const hjsv = 6

  return (
    <>
      {element}

      <Script
        id="ana"
        key="ana"
        defer
        data-domain="infernocomms.com"
        data-api="/an/ev"
        src="/an/an.js"
      ></Script>

      <Script
        id="hj-snippet"
        key="hj-snippet"
        dangerouslySetInnerHTML={{
          __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:${hjid},hjsv:${hjsv}};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
          `,
        }}
      />

      <Script
        id="ze-snippet"
        key="ze-snippet"
        defer
        src="https://static.zdassets.com/ekr/snippet.js?key=cde1ff35-ab21-4ddf-aaac-dabdc7eb1a66"
      ></Script>
      
    </>
  )
  /*
<Script
        id="lc-snippet"
        key="lc-snippet"
        dangerouslySetInnerHTML={{
          __html: `
            window.__lc = window.__lc || {};
            window.__lc.license = 15933882;
            ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
          `,
        }}
      />

  return (
    <>
      {element}

      <Script
        id="ze-snippet"
        key="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=cde1ff35-ab21-4ddf-aaac-dabdc7eb1a66"
      />
      <Script
        id="hj-snippet"
        key="hj-snippet"
        dangerouslySetInnerHTML={{
          __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:${hjid},hjsv:${hjsv}};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
          `,
        }}
      />
    </>
  )*/

  /*const Zendesk = (
    <Script
        id="ze-snippet"
        key="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=cde1ff35-ab21-4ddf-aaac-dabdc7eb1a66"
      />
  )

  const hjid = 3088760
  const hjsv = 6

  const Hotjar = (
    <Script
        id="hj-snippet"
        key="hj-snippet"
        dangerouslySetInnerHTML={{
          __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:${hjid},hjsv:${hjsv}};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
          `,
        }}
      />
  )
  
  if(process.env.NODE_ENV === 'production') {
    return (
      <>
        {element}
  
        <Zendesk />
        <Hotjar />
      </>
    )
  } else {
    return (
      <>
        {element}
  
        <Zendesk />
      </>
    )
  }*/
  
}