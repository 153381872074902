import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
//import storage from 'redux-persist/lib/storage'
import AsyncStorage from '@react-native-async-storage/async-storage'

const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_SKU':
      return { ...state, basket: { ...state.basket, [action.sku]: (state.basket[action.sku] ?? 0) + 1 } }
    case 'REM_SKU':
      const newState = { ...state, basket: { ...state.basket }}
      if (state.basket[action.sku] === 1) delete newState.basket[action.sku]
      else --newState.basket[action.sku]
      return newState
    default:
      return state
  }
}

const persistConfig = {
  key: 'root',
  storage: AsyncStorage
}
const persistedReducer = persistReducer(persistConfig, reducer)

const initialState = { basket: {} }

const state = (preloadedState = initialState) => {
  const store = createStore(
    persistedReducer,
    { basket: {} },
    //preloadedState, // initial state
  )
  const persistor = persistStore(store)
  return { store, persistor }
}

export default state