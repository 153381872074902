exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-articles-what-is-an-asn-js": () => import("./../../../src/pages/articles/what-is-an-asn.js" /* webpackChunkName: "component---src-pages-articles-what-is-an-asn-js" */),
  "component---src-pages-colocation-js": () => import("./../../../src/pages/colocation.js" /* webpackChunkName: "component---src-pages-colocation-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-index-js": () => import("./../../../src/pages/info/index.js" /* webpackChunkName: "component---src-pages-info-index-js" */),
  "component---src-pages-info-payments-js": () => import("./../../../src/pages/info/payments.js" /* webpackChunkName: "component---src-pages-info-payments-js" */),
  "component---src-pages-legal-general-terms-js": () => import("./../../../src/pages/legal/general-terms.js" /* webpackChunkName: "component---src-pages-legal-general-terms-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-lir-services-js": () => import("./../../../src/pages/lir-services.js" /* webpackChunkName: "component---src-pages-lir-services-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-network-index-js": () => import("./../../../src/pages/network/index.js" /* webpackChunkName: "component---src-pages-network-index-js" */),
  "component---src-pages-network-peering-js": () => import("./../../../src/pages/network/peering.js" /* webpackChunkName: "component---src-pages-network-peering-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */)
}

